<template>
  <middleware-sidebar-outline
    :uuid="uuid"
    :middlewareID="middlewareID"
    :middlewareType="middlewareType"
    @close="$emit('close')"
    @shown="$emit('shown')"
    @saveMiddleware="buildAgents"
    v-model="variables"
  >
  <div v-if="propagatorData.immediate ">
    <b-row class="mt-3" no-gutters>
      <b-col md="6" class="mt-1">
        <label for="event-select">
          <helper-tooltip
            size="13"
            innerText="Select the event that you want to be propagated"
            tooltipPlacement="left"
          />
          EVENT
        </label>
        <v-select
          v-if="options"
          id="event-select"
          v-model="propagatorData.event.register_1.value"
          :options="options"
          :reduce="(option) => option.event_identifier || option"
          :getOptionLabel="(option) => option.event_identifier || option"
        >
          <template slot="no-options">
            {{ $t('common.terms.no_options') }}
          </template>
          <template v-slot:option="option">
            {{ option.event_identifier }}
          </template>
        </v-select>
        <b-skeleton v-else 
          height="37px"
        />
      </b-col>

      <b-col md="3" class="mb-2 mt-1 d-flex justify-content-start">
        <switch-register v-model="propagatorData.immediate.register_1">
          Run Synchronously
          <helper-tooltip
            size="15"
            innerText="eopae"
            tooltipPlacement="right"
          />
        </switch-register>
      </b-col>

      <b-col md="3" class="mb-2 mt-1 d-flex justify-content-start">
        <b-collapse :visible="propagatorData.immediate.register_1.value == 'true'" appear>
          <switch-register v-model="propagatorData.merge.register_1">
            Merge Responses 
            <helper-tooltip
              size="15"
              innerText="eopae"
              tooltipPlacement="right"
            />
          </switch-register>
        </b-collapse>
      </b-col>

    </b-row>
    <b-row class="mt-3" no-gutters>
      <b-col cols="12">
        <hr class="w-100">
        <div class="d-flex justify-content-between mt-2">
          <div></div>
          <b-form-checkbox
            switch
            class="custom-control-primary mb-50"
            v-model="advancedBody"
          >
            Advanced
            <helper-tooltip
              size="15"
              innerText="eopae"
              tooltipPlacement="right"
            />
          </b-form-checkbox>
        </div>
        <b-form-group
          v-if="!advancedBody"
          :label="$t('middleware.modal.input.label.json_body')"
          label-for="detect-json"
          class="w-100 mt-1"
        >
        
            <json-editor
              v-if="type === 'json'"
              id="detect-json"
              key="detect-json"
              v-model="propagatorData.body.register_1.value"
              :modes="['code', 'tree', 'preview']"
              height="450px"
            />
          <!-- </drop> -->
        </b-form-group>
        <actions-reorder-list
          v-else
          :key="getID(`actions-reorder-list-${version}`)"
          class="w-100"
          :middlewareID="middlewareID"
          v-model="agents"
          @agentAdded="version++"
          @agentEdited="version++"
          @agentDeleted="version++"
        />
      </b-col>
    </b-row>
  </div>
</middleware-sidebar-outline>
</template>


<script>
import {
  BContainer,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BTab,
  BFormSelect,
  BTabs,
  BFormSelectOption,
  BFormCheckbox,
  BSkeleton,
  BSpinner,
  BCollapse
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { v4 as uuidv4 } from "uuid";
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import VSelect from 'vue-select';
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import { makeToast } from "@/layouts/components/Popups";
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent.js'
import VariablesPanel from "../VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import SwitchRegister from "@/views/pages/middleware/MiddlewareManagement/Components/SwitchRegisterInput.vue"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

export default {
  mixins: [MiddlewaresMixin],
  components: {
    BContainer,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BForm,
    BTab,
    BTabs,
    VuePerfectScrollbar,
    BInputGroupAppend,
    BInputGroupPrepend,
    JsonEditor,
    BFormSelect,
    HelperTooltip,
    BFormSelectOption,
    BFormCheckbox,
    ActionsReorderList,
    VSelect,
    BSkeleton,
    BSpinner,
    VariablesPanel,
    CustomInput,
    SwitchRegister,
    BCollapse,
    MiddlewareSidebarOutline,
  },
  props: {
    i18nKey: {
      type: String,
      default: "propagator"
    },
    uuid: {
      type: String,
      required: true,
    },
    middlewareID: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "json",
    }
  },
  data() {
    return {
      uuidMap: {},
      string: "",
      variables: undefined,
      variant_map: undefined,
      middlewareType: new Middlewares().items.find(el=> el.id == 6),
      
      options: null,
      propagatorData: {
        event: undefined,
        body: undefined,
        immediate: undefined,
        merge: undefined,
      },

      nameField:{
        event: 'IDENTIFIER',
        body: 'DATA',
        immediate: 'SYNC',
        merge: 'MERGE',
      },

      agentList: [],
      advancedBody: false,
      version: 0,

      isSaving: false,

      defaultAgentActionID: 3,
      defaultAgentBlockID: 1
    };
  },
  computed: {
    agents: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    transmissionID() {
      return this.$route.params.transmissionID
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getEventsByTransmission()
      this.initializeAgents();
    },
    getEventsByTransmission() {
      this.$store
        .dispatch("fetchEventsByTransmissionGroup", {
          transmissionID: this.transmissionID,
        })
        .then((response) => {
          this.options = response || [];
        })
        .catch(() => {
          makeToast({
            title: this.$t(`default_changer.toast.submit.error.title`),
            text: this.$t(`default_changer.toast.submit.error.text`),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    highlighter(code) {
      let lang = undefined;
      try {
        JSON.parse(code);
        lang = languages.json;
      } catch (e) {
        var xmlString = this.propagatorData.body.register_1.value;
        var domParser = new DOMParser();
        var dom = domParser.parseFromString(xmlString, 'text/xml');

        if(dom.documentElement.nodeName != 'parsererror') {
          lang = languages.xml;
        } else {
          lang = languages.plain;
        }
      }

      return highlight(code, lang);
    },
    isObject(item) {
      return item instanceof Object && !(item instanceof Array);
    },
    isArray(item) {
      return item instanceof Array;
    },
    initializeAgents() {
      Object.keys(this.propagatorData).forEach(pos => {
        this.propagatorData[pos]= MiddlewareFunctions.getValueFrom(
        {
          default: DefaultAgent.setAgent(this, {
            id: null,
            enum_agent_action_id: this.defaultAgentActionID,
            enum_agent_block_id: this.defaultAgentBlockID,
            register_1: {
              source: 7,
            },
            register_2: {},
            register_destiny: {
              source: 6,
              value: this.nameField[pos],
            },
            execution_order: 1,
            fatal_on_fail: true,
          }),
          source: 6,
          value: this.nameField[pos],
          from: {
            source: "register_destiny",
            value: "register_destiny",
          },
        },
        this.agents
      );
    }); 


      this.propagatorData.body = MiddlewareFunctions.getValueFrom(
        {
          default: (this.type === 'json' ? 
            
            DefaultAgent.setAgent(this, {
              id: null,
              enum_agent_action_id: this.defaultAgentActionID,
              enum_agent_block_id: this.defaultAgentBlockID,
              register_1: {
                source: "7",
                value: {}
              },
              register_2: {},
              register_destiny: {
                source: "6",
                value: this.nameField['body'],
              },
              execution_order: 1,
              fatal_on_fail: true,
            }) 
            
            :

            DefaultAgent.setAgent(this, {
              id: null,
              enum_agent_action_id: this.defaultAgentActionID,
              enum_agent_block_id: this.defaultAgentBlockID,
              register_1: {
                source: "7",
              },
              register_2: {},
              register_destiny: {
                source: "6",
                value: this.nameField['body'],
              },
              execution_order: 1,
              fatal_on_fail: true,
            })
            
          ),
          source: "6",
          value: this.nameField['body'],
          from: {
            value: "register_destiny",
            source: "register_destiny",
          },
          data_modifier: (el) => {
            return {
              ...el,
              register_1: { 
                value: (this.type === 'json' ? JSON.parse(el.register_1.value) : el.register_1.value),
                source: el.register_1.source
              }
            }
          },
        },
        this.agents
      );
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    buildAgents() {
      this.isSaving = true
      this.agentList = [];

      if (this.propagatorData.body.register_1.value && this.propagatorData.body.register_1.value) {
        if (this.type == 'json') {
          this.propagatorData.body.register_1.value = JSON.stringify(this.propagatorData.body.register_1.value)
        }
        if ((this.propagatorData.body.register_1.value.length > 4)){
          this.agentList.push(DefaultAgent.defineToAPI(this.propagatorData.body, this.middlewareID));
        }
      }
      this.agentList.push(DefaultAgent.defineToAPI(this.propagatorData.event, this.middlewareID));
      

      if (this.propagatorData.immediate.register_1.value=='true') {
        this.agentList.push(DefaultAgent.defineToAPI(this.propagatorData.immediate, this.middlewareID));
      }

      if (this.propagatorData.merge.register_1.value=='true' && this.propagatorData.immediate.register_1.value=='true') {
        this.agentList.push(DefaultAgent.defineToAPI(this.propagatorData.merge, this.middlewareID));
      }

      this.saveAgents();
    },
    saveAgents() {
      this.$store
        .dispatch("saveAgents", {
          agentList: this.agentList,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });

          this.$emit("saved", response.data);
          this.isSaving = false
        })
        .catch((error) => {
          makeToast({
            title: this.$t("agent.toast.create_agents.success.title"),
            text: this.$t("agent.toast.create_agents.success.message"),
            variant: "danger",
            icon: "XIcon",
          });
          this.isSaving = false
        });
    },
    deleteAgent(item, index, type) {
      this.$store
        .dispatch("deleteAgent", {
          id: item.id,
          transmissionID: this.$route.params.transmissionID,
        })
        .then(() => {
          makeToast({
            title: this.$t("agent.toast.delete.success.title"),
            text: this.$t("agent.toast.delete.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          this.$refs[this.getID(type)].addItemAt(item, index);
          makeToast({
            title: this.$t("agent.toast.delete.error.title"),
            text: this.$t("agent.toast.delete.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    sidebarShown() {
this.$emit("shown")
      let el = document.getElementsByTagName("html");
      el[0].classList.add("hide-scrollbar");
    },
    sidebarHidden() {
      this.$emit("close");

      let el = document.getElementsByTagName("html");
      el[0].classList.remove("hide-scrollbar");
    },
  },
};
</script>

<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.height-400 {
  height: 400px;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;
  }

  .no-limit-height {
    height: calc(100%) !important;
  }

}
</style>


<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>


<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}

.test-terminal {
  max-height: 80vh;
  .submit-button {
    margin-top: 22px;
  }
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.json-input-disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
</style>
